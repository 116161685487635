@tailwind base;
@tailwind components;
@tailwind utilities;
@import "katex/dist/katex.min.css";

.math-content {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.math-content .katex {
  white-space: normal;
  word-wrap: break-word;
}

.math-content .katex-html {
  max-width: 100%;
  overflow-x: auto;
}
.katex-html {
  white-space: normal;
  word-wrap: break-word;
}

.katex {
  display: inline-block;
  white-space: nowrap;
  text-indent: 0;
  margin-right: 0.1em;
  margin-left: 0.1em;
}

/* Add responsive math formula sizing */
.math-content .katex {
  font-size: 1.1em; /* Default size */
}

/* Mobile devices */
@media screen and (max-width: 768px) {
  .math-content .katex {
    font-size: 0.9em;
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .math-content .katex {
    font-size: 0.8em;
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */

  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
